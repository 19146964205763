import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import DefaultImage from '../../../../assets/images/history.png';
import MediaPageHeaderDesign from '../../../../assets/images/mediapage-design-header.inline.svg';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getImage, getBreadCrumb } from '../../../../Utils/Utils';

import './styles.scss';
let classNames = require('classnames');

const CommiteMemberPage = ({ data }) => {
  const imagesArray = data?.allImages?.edges;
  const biographyData = data?.biographyData.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, biographyData.path?.alias);
  const visuel = getImage(imagesArray, biographyData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp;

  const image_sources = [
    {
      ...visuel?.fluid,
      media: '(max-width: 768px)',
      aspectRatio: 1.11290323
    },
    {
      ...visuel?.fluid,
      media: '(min-width: 768px)',
      aspectRatio: 0.840057637
    },
  ];

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={biographyData.title}
        description={biographyData.body?.processed}
      />
      <div className={classNames('page_template', 'comite_member_page')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              grandparentPage: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
              gparent: { title: breadCrumb?.gGrandParent?.name, url: breadCrumb?.gGrandParent?.link },
              parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
              currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
              locale: biographyData.langcode
            }}
          />
        </div>
        <section className="section_content section_member_direction">
          <div className="wrapper_page">
            <TitleSection
              type="line"
              title={biographyData.title}
              description={biographyData.field_poste}
              normalText={biographyData.field_date_de_naissance}
            />
          </div>
          <div className="wrapper_page_xs">
            <div className={classNames('col_2_special', 'cross_wrapper_extreme_left')}>
              <div className="visuel">
                {visuel &&
                  <Img
                    style={{ height: '100%', width: '100%' }}
                    fluid={image_sources}
                    alt={biographyData.title}
                  />
                }
                {!visuel && DefaultImage &&
                  <img src={DefaultImage} alt={biographyData.title} />
                }
              </div>
              <div className={classNames('text')}>
                <div dangerouslySetInnerHTML={{ __html: biographyData.body?.processed }}></div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  );
};

export const query = graphql`
  query biographyTemplateQuery(
    $locale: String!
    $slug: String!, $imageID: [Int!]
  ) {
    biographyData: allNodeBiographie(
      filter: {
        langcode: {eq: $locale},
        path: {alias: {eq: $slug}},
        status: {eq: true}
      }
    ) {
      edges {
        node {
          title
          langcode
          body {
            processed
          }
          field_date_de_naissance
          field_poste
          relationships {
            field_image {
              drupal_internal__mid
            }
          }
          path {
            alias
            langcode
          }
          drupal_id
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: {eq: "fr"},
        drupal_internal__mid: {in: $imageID}
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 332, height: 332) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }

    allMenu :   allMenuLinkContentMenuLinkContent(
      filter: {langcode: {eq: $locale}}
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default CommiteMemberPage;
